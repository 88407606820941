/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../assets/favicons/favicon.ico"
import favicon16x16 from "../assets/favicons/favicon-16x16.png"
import favicon32x32 from "../assets/favicons/favicon-32x32.png"
import appleTouchIcon180x180 from "../assets/favicons/apple-touch-icon.png"
import favicon192x192 from "../assets/favicons/android-chrome-192x192.png"
import favicon512x512 from "../assets/favicons/android-chrome-512x512.png"
import seoImage from "../assets/PoL_seo_01.jpg"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={[
        { rel: "icon", type: "image/ico", href: `${favicon}` },
        {
          rel: "icon",
          type: "image/png",
          href: `${favicon16x16}`,
          sizes: "16x16",
        },
        {
          rel: "icon",
          type: "image/png",
          href: `${favicon32x32}`,
          sizes: "32x32",
        },
        {
          rel: "icon",
          type: "image/png",
          href: `${favicon32x32}`,
          sizes: "32x32",
        },
        {
          rel: "apple-touch-icon-precomposed",
          sizes: "180x180",
          href: `${appleTouchIcon180x180}`,
        },
        {
          rel: "icon",
          type: "image/png",
          href: `${favicon192x192}`,
          sizes: "192x192",
        },
        {
          rel: "icon",
          type: "image/png",
          href: `${favicon512x512}`,
          sizes: "512x512",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        { property: `og:url`, content: `${site.siteMetadata.siteUrl}` },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${seoImage}`,
        },
        {
          property: `fb:app_id`,
          content: `231896337900835`,
        },
        { name: "og:image:width", content: "1200" },
        { name: "og:image:height", content: "630" },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        { name: "twitter:card", content: "summary_large_image" },
        {
          property: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}${seoImage}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
